import {
  DefaultCardsV2,
  RichText,
  DefaultCardV2,
  toImageProps,
  EDefaultCardV2TopIconSize,
  EDefaultCardV2RightIconSize,
  EDefaultCardV2Size,
  EDefaultCardV2Paddings,
  EDefaultCardV2TitleSize,
  EDefaultCardV2Theme,
  EDefaultCardV2ImagePosition,
  EDefaultCardV2ImageSize,
  isRichTextContentEmpty,
  EDefaultCardsHorizontalScroll,
  EDefaultCardV2DescriptionSize,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { useAppDispatch } from '../../../store/store.hooks';
import toCardButtons from '../../../utils/toCardButtons';
import { useSectionAnimationContext } from '../../sections';

import type { Section } from '@shared/master-types';
import { handleOpenModal } from '@front/master';

export type TDefaultCardsV2ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'default-cards-v2' }
>;

type TCard = Required<TDefaultCardsV2ConnectedProps>['cards'][number] & {
  imageSize?: EDefaultCardV2ImageSize;
};

export const DefaultCardsV2Connected: React.FC<
  TDefaultCardsV2ConnectedProps
> = props => {
  const { cards = [], horizontalScroll, ...rest } = props;
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  const sectionAnimationContext = useSectionAnimationContext();
  const isSectionAnimationSequenceReady = sectionAnimationContext
    ? sectionAnimationContext?.isSectionAnimationSequenceReady
    : true;

  const banners: React.ComponentProps<typeof DefaultCardV2>[] = useMemo(() => {
    return cards.map(card => {
      const {
        image,
        topIcon,
        rightIcon,
        title,
        description,
        buttons,
        topIconSize,
        rightIconSize,
        titleSize,
        eyebrows,
        imagePosition,
        descriptionSize,
        imageSize,
        paddings,
        theme,
        size,
        isRoundedIcon,
      } = card as TCard;

      const openModal = handleOpenModal(appDispatch);

      const defaultCard = {
        ...card,
        image: toImageProps(image),
        topIcon: toImageProps(topIcon),
        rightIcon: toImageProps(rightIcon),
        title:
          title && !isRichTextContentEmpty(title) ? (
            <RichText onOpenModal={openModal} content={title} />
          ) : null,
        description:
          description && !isRichTextContentEmpty(description) ? (
            <RichText onOpenModal={openModal} content={description} />
          ) : null,
        eyebrows: (eyebrows || []).map(eyebrow => eyebrow.text),
        buttons: toCardButtons(buttons, currentLocale, appDispatch),
        topIconSize: topIconSize as EDefaultCardV2TopIconSize,
        rightIconSize: rightIconSize as EDefaultCardV2RightIconSize,
        titleSize: titleSize as EDefaultCardV2TitleSize,
        imagePosition: imagePosition as EDefaultCardV2ImagePosition,
        imageSize: imageSize as EDefaultCardV2ImageSize, // DEPRECATED, backward compatibility usage ONLY
        paddings: paddings as EDefaultCardV2Paddings,
        descriptionSize: descriptionSize as EDefaultCardV2DescriptionSize,
        theme: theme as EDefaultCardV2Theme,
        size: size as EDefaultCardV2Size,
        isRoundedIcon: !!isRoundedIcon,
      };

      return defaultCard;
    });
  }, [cards, currentLocale, appDispatch]);

  return (
    <DefaultCardsV2
      banners={banners}
      isSectionAnimationReady={isSectionAnimationSequenceReady}
      horizontalScroll={horizontalScroll as EDefaultCardsHorizontalScroll}
      {...rest}
    />
  );
};

export default DefaultCardsV2Connected;
