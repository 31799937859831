import clsx from 'clsx';
import React from 'react';

import { Button, EButtonTheme } from '../../../atoms/Button';

type TButtonsProps = {
  buttons?: React.ComponentProps<typeof Button>[];
  buttonTheme?: EButtonTheme;
  className?: string;
};

export const Buttons: React.FC<TButtonsProps> = ({
  buttons,
  buttonTheme,
  className,
}) => {
  if (!buttons?.length) {
    return null;
  }

  return (
    <div
      className={clsx('flex flex-row flex-wrap items-center gap-6', className)}
    >
      {buttons.map((button, index) => (
        <Button key={index} theme={buttonTheme} {...button}>
          {button.children}
        </Button>
      ))}
    </div>
  );
};
