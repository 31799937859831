import { cva } from 'cva';
import React from 'react';

import {
  EDefaultCardV2DescriptionSize,
  EDefaultCardV2Size,
} from '../DefaultCardV2.constants';
import clsx from 'clsx';

const commonFontClasses = 'text-base leading-mega-loose';
export const descriptionCVA = cva(
  'transition empty:hidden [overflow-wrap:anywhere] ' +
    'prose-a:text-brand-500  ' +
    'prose-ol:ps-[1.5rem] prose-ol:pt-4 prose-ul:pt-4 ' +
    'prose-p:pe-2 prose-li:pb-2 last:prose-li:pb-0',
  {
    variants: {
      size: {
        [EDefaultCardV2Size.L]: commonFontClasses,
        [EDefaultCardV2Size.M]: commonFontClasses,
        [EDefaultCardV2Size.S]: 'text-sm leading-mega-loose xl:text-base',
        [EDefaultCardV2Size.Xs]: commonFontClasses,
      },
      descriptionSize: {
        [EDefaultCardV2DescriptionSize.Medium]: 'text-base',
        [EDefaultCardV2DescriptionSize.Small]:
          '!prose:p:pt-[8px] !pt-0 !text-xs',
      },
    },
    defaultVariants: {
      descriptionSize: EDefaultCardV2DescriptionSize.Medium,
    },
  },
);

type TDescriptionProps = {
  description?: React.JSX.Element | string | null;
  descriptionSize?: EDefaultCardV2DescriptionSize;
  className?: string;
};

export const Description: React.FC<TDescriptionProps> = ({
  description,
  descriptionSize,
  className,
}) => {
  if (!description) {
    return null;
  }

  return (
    <div
      className={clsx(descriptionCVA({ descriptionSize, className }), {
        'prose-p:pt-2': descriptionSize === EDefaultCardV2DescriptionSize.Small,
        'prose-p:pt-4':
          descriptionSize === EDefaultCardV2DescriptionSize.Medium,
      })}
    >
      {description}
    </div>
  );
};
